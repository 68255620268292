import { useState, useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import { IoChevronForwardCircle, IoChevronBackCircleSharp } from "react-icons/io5";

const RCarousel = (props) => {
  const { data, children } = props;

  const carouselSettings = {
    centerMode: true,
    infiniteLoop: true,
    showIndicators: false,
    showStatus: false,
    showThumbs: false, // Assurez-vous de ne pas afficher les vignettes
    autoPlay: true,    // Active le défilement automatique
    interval: 2800     // Définit le temps entre chaque changement automatique (2000 ms = 2 secondes)
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = data.length;

  // Gestion de la navigation automatique
  useEffect(() => {
    const autoNavigate = setInterval(() => {
      handleNext();
    }, 2000); // 2000 ms = 2 secondes

    return () => clearInterval(autoNavigate);
  }, [currentIndex]);

  const handleClickItem = (index) => {
    setCurrentIndex(index);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + totalSlides) % totalSlides;
    setCurrentIndex(prevIndex);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % totalSlides;
    setCurrentIndex(nextIndex);
  };

  return (
    <div className="relative">
      <div className='absolute w-full z-10 flex justify-between h-fit px-4 top-0 bottom-0 my-auto text-blue-200'>
        <IoChevronBackCircleSharp onClick={handlePrev} className="cursor-pointer w-6 h-6" />
        <IoChevronForwardCircle onClick={handleNext} className="cursor-pointer w-6 h-6" />
      </div>
      <Carousel {...carouselSettings} selectedItem={currentIndex}>
        {children}
      </Carousel>
    </div>
  )
};

export default RCarousel;
