import React, { useState } from 'react';

const TermsOfServiceModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleModal} className="hover:underline text-white py-2 px-4">
        Terms of Service
      </button>      
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-auto flex bg-gray-500 bg-opacity-75">
            <div className="relative p-8 bg-white w-full max-w-6xl m-auto flex-col flex rounded-lg">                
                <span className="absolute top-0 right-0 p-4">
                  <button onClick={toggleModal} className="text-dark font-semibold">Close</button>
                </span>
                <div className="text-dark overflow-y-auto max-h-[80vh]">
                    <br></br>
                    <br></br>
                    <h1><strong>BUCKETS – TERMS OF SERVICES</strong></h1>
                    <br></br>
                      <p>Effective date: 19 April 2024</p>
                      <br></br>
                      <p>By utilizing or engaging with the Platform, you consent to be legally bound by these Terms. It is important to carefully review this document. Should you disagree with any of the provisions contained within these Terms, you are advised not to use the Platform.</p>
                      <br></br>
                      <ol>
                        <li>1. GENERAL</li>
                        <li>2. USER’S RESPONSIBILITIES AND ASSUMPTION OF RISKS</li>
                        <li>3. PERMITTED USE</li>
                        <li>4. DISCLAIMER OF WARRANTIES</li>
                        <li>5. LIMITATION OF LIABILITY</li>
                        <li>6. INTELLECTUAL PROPERTY</li>
                        <li>7. FORCE MAJEURE</li>
                        <li>8. INDEMNIFICATION</li>
                        <li>9. SEVERITY</li>
                        <li>10. GOVERNING LAW AND DISPUTES</li>
                        <li>11. MISCELLANEOUS</li>
                        <li>12. CONTACT</li>
                      </ol>
                      <br></br>

                      <h2>1. GENERAL</h2>
                      <p>1.1 These Buckets Terms of Services (the "Terms") regulate the access and use of the Buckets website https://www.buckets.fi, along with its related software applications and services (collectively, the "Platform") for both individual users and legal entities (referred to as "user" and "you"). These Terms set forth the guidelines and conditions for the user's access to and utilization of the Platform.</p>
                      <p>1.2 License to Use the Platform. Buckets provides the user with a global, personal, revocable, non-exclusive, non-transferable, and limited license to operate the Platform in accordance with these Terms.</p>
                      <p>1.3 Disclaimer. While Buckets routinely monitors the information on the Platform, it does not assure the accuracy, dependability, timeliness, relevance, or completeness of the information provided, whether by Buckets or third parties.</p>
                      <p>1.4 Third-party Links. The Platform may include links to third-party websites, applications, and other online resources. Buckets is not accountable for the content, information, or advertisements provided through these third-party links, nor for the security and privacy practices of these sites. Users are solely responsible for conducting due diligence prior to engaging with any linked third-party resources.</p>
                      <p>1.5 Age Limitations. The Platform is not accessible to individuals who are incapable of entering into legally binding contracts under the laws of their respective countries of residence.</p>
                      <p>1.6 Privacy and Other Relevant Terms. The following documents, which should be read and understood in conjunction with these Terms, include crucial information concerning the use of the Platform:</p>
                      <ul>
                        <li>Our Privacy Policy, detailing how Buckets processes personal data collected through the Platform.</li>
                        <li>Our Risks Disclaimer, which briefly outlines the risks associated with investments in Decentralized Finance (“DeFi”).</li>
                        <li>Additional specific terms of services provided on the Platform.</li>
                      </ul>
                      <p>1.7 About the Platform. Contingent upon availability, the Platform permits you to: (i) use a third-party service provider https://www.dynamic.xyz to establish a digital wallet for managing your DeFi portfolio, including debts, assets, and liquidity; (ii) execute certain DeFi transactions such as exchanges, investments, and rebalancing of supported DeFi platforms; (iii) access specific DeFi applications; (iv) discover unique investment opportunities; and (v) assess your risk profile. It is important to note that the Platform operates on a non-custodial basis, meaning that Buckets does not possess access to your private keys and cannot initiate any DeFi transfers without your active participation. Buckets is not a broker, intermediary, agent, advisor, or custodian and therefore does not hold a fiduciary duty or obligation towards you regarding any decisions or activities undertaken while using the Platform. Furthermore, Buckets does not host, maintain, or endorse any third-party services accessible through the Platform, nor does it participate in any transactions or endorse any particular use of the Platform.</p>
                      <br></br>

                      <h2>2. USER’S RESPONSIBILITIES AND ASSUMPTION OF RISKS</h2>
                      <p>2.1 The Wallet. The Platform permits users to create a digital wallet that supports the storage and transfer of virtual currencies (referred to as the “Wallet”). This Wallet facilitates the monitoring of a user's portfolio of virtual currencies and interaction with decentralized protocols and platforms (e.g., virtual currency markets). The creation of the Wallet is enabled through the third-party service provider https://www.dynamic.xyz. Users must ensure the confidentiality and security of the Wallet, its access credentials (e.g., private keys, pin codes, passwords), and any related computing devices. Buckets disclaims any responsibility for unauthorized access to the Wallet. Importantly, Buckets does not access or store virtual currencies, private keys, or any other Wallet access credentials, nor does it request such information from users.</p>
                      <p>2.2 The Transactions. Buckets does not undertake any obligations to verify or investigate the accuracy or validity of transactions conducted via the Platform (referred to as “Transactions”). Buckets does not control the underlying networks or virtual currencies, cannot confirm transaction details on the network, and does not handle requests for transaction modifications or cancellations. Moreover, Buckets does not provide financial or technical advice regarding Transactions or the Platform's use.</p>
                      <p>2.3 Buckets’s Role. Buckets is neither a cryptocurrency exchange, broker, intermediary, advisor, agent, nor custodian, and holds no fiduciary duty towards users related to their decisions or actions under the Platform's use. Buckets assumes no liability for:</p>
                      <ul>
                        <li>Advancements in cryptography that may compromise cryptographic algorithms.</li>
                        <li>Security of any networks or the markets in which cryptocurrencies are traded.</li>
                        <li>Any network attacks such as hacks, double spending, or currency theft.</li>
                        <li>Illegal activities conducted by the user using the Platform.</li>
                        <li>Transaction failures including incomplete, delayed, modified, or canceled transactions.</li>
                        <li>Regulatory oversight or compliance.</li>
                      </ul>
                      <p>2.4 Risks. Engaging with DeFi involves significant risks, as detailed in the Risks Disclaimer. By using the Platform, users acknowledge and accept these risks associated with DeFi, such as those involving virtual currencies and blockchain technologies. Buckets explicitly disclaims participation in or responsibility for any Transactions or user activities on the Platform.</p>
                      <p>2.5 User's Responsibilities. Users acknowledge and accept responsibility for:</p>
                      <ul>
                        <li>Possessing the necessary technical expertise to manage their Wallet securely.</li>
                        <li>Understanding the inherent risks and compliance requirements of their activities on the Platform.</li>
                        <li>Conducting necessary research on blockchain and DeFi-related risks.</li>
                        <li>Bearing full responsibility for all activities and Transactions linked to their use of the Platform, including securing access to their Wallet.</li>
                        <li>Adapting to changes in network protocols which may significantly affect the Platform's operation.</li>
                      </ul>
                      <p>2.6 Taxes and Duties. Users are responsible for all tax obligations, including the determination, payment, withholding, filing, and reporting of taxes and other governmental assessments related to their activities on the Platform.</p>
                      <p>2.7 User's Content. Users are solely responsible for the content they generate, upload, or share on the Platform, including its legality, reliability, and appropriateness.</p>
                      <br></br>

                      <h2>3. PERMITTED USE</h2>
                      <p>3.1 User Compliance Responsibility. Users are obligated to evaluate whether their use of the Platform adheres to local laws, regulations, and economic sanctions. Usage of the Platform is strictly forbidden where prohibited by applicable laws or regulations. It is the responsibility of the user to secure any necessary permits or licenses before using the Platform. Buckets is not liable for any use of the Platform that contravenes applicable laws, regulations, or sanctions.</p>
                      <p>3.2 Prohibited Uses of the Platform. Users are expressly prohibited from engaging the Platform in any manner that supports or contributes to the following activities (note this list is illustrative and not exhaustive):</p>
                      <ul>
                        <li>Engaging in any illegal activities, including breaches of any laws, statutes, ordinances, or regulations.</li>
                        <li>Infringing on intellectual property rights, such as copyrights or trademarks.</li>
                        <li>Unauthorized access to computers, networks, data, or other breaches of cyber security.</li>
                        <li>Advocating, promoting, or participating in terrorist activities.</li>
                        <li>Exploiting minors in any capacity.</li>
                        <li>Committing fraud or providing false, inaccurate, or misleading information.</li>
                        <li>Distributing malware (e.g., viruses, worms, Trojan horses), spam, or engaging in illegal messaging.</li>
                        <li>Disseminating information that is ethnically, racially, or otherwise offensive.</li>
                        <li>Spreading sexually explicit, libelous, harassing, defamatory, abusive, vulgar, threatening, or obscene content.</li>
                        <li>Copying, distributing, selling, modifying, or otherwise exploiting the Platform in unauthorized ways.</li>
                        <li>Engaging in gambling, including but not limited to contests, lotteries, betting, and sweepstakes.</li>
                        <li>Harassing or abusing other users of the Platform.</li>
                        <li>Utilizing automated methods such as bots or scripts for unauthorized purposes.</li>
                        <li>Collecting or disclosing any personal information about other users without consent.</li>
                      </ul>
                      <p>3.3 Breach of Terms Enforcement. Should Buckets determine, at its sole discretion, that a user has violated these Terms, and it deems action necessary or desirable, Buckets may:</p>
                      <ul>
                        <li>Issue a formal warning to the user.</li>
                        <li>Temporarily or permanently restrict the user's access to the Platform.</li>
                        <li>Report the user to relevant public authorities.</li>
                        <li>Initiate legal proceedings against the user.</li>
                      </ul>
                      <br></br>

                      <h2>4. DISCLAIMER OF WARRANTIES</h2>
                      <p>4.1 Platform Provision and Disclaimers. Buckets offers the Platform on an "AS AVAILABLE", "AS IS", and "WITH ALL FAULTS" basis. Within the bounds of applicable law, Buckets makes no claims concerning the reliability, suitability, or accuracy of the Platform or any content accessed through it, whether provided by Buckets, its users, or third parties. Consequently, Buckets expressly disclaims all warranties related to the Platform and its functionality.</p>
                      <p>4.2 User's Responsibility for Assessment. It is the sole responsibility of the user to evaluate the appropriateness of the Platform for their intended purposes before use. Users must determine whether the Platform adequately meets their needs.</p>
                      <p>4.3 Use of Third-party Suppliers. In utilizing the Platform, the user acknowledges that Buckets may engage third-party suppliers to provide software, hardware, storage, networking, and other technological services. The selection of these third-party suppliers is not within the user’s discretion. Buckets is not responsible for any acts or omissions of these third-party suppliers, which may be beyond Buckets' reasonable control. Accordingly, Buckets excludes any liability for losses or damages arising from such third-party involvements to the fullest extent permitted by law.</p>
                      <p>4.4 Preservation of Statutory Consumer Rights. Nothing in these Terms shall compromise or waive the statutory rights of the user as a consumer, which are inviolable and cannot be contractually relinquished.</p>
                      <br></br>

                      <h2>5. LIMITATION OF LIABILITY</h2>
                      <p>5.1 Limitation of Liability. Except as restricted or excluded by applicable law, Buckets will not be liable for any damages, including but not limited to incidental, punitive, special, or consequential damages arising from or related to the user's access to and use of the Platform, any content available through the Platform, any transactions conducted via the Platform, or any unauthorized or illegal use of the Platform. Users agree not to hold Buckets accountable for any losses that occur due to circumstances beyond Buckets's reasonable control.</p>
                      <p>5.2 Applicability of Liability Limitations. The limitations of liability outlined in Section 7 apply regardless of whether Buckets was previously advised of or should have been aware of the possibility of such losses.</p>
                      <br></br>

                      <h2>6. INTELLECTUAL PROPERTY</h2>
                      <p>6.1 Platform Content Ownership. The Platform hosts a variety of content, including information, software, data, logos, marks, designs, graphics, pictures, video and sound files, and illustrations (collectively known as “Platform Content”), which are the property of Buckets, its partners, agents, licensors, vendors, and other content providers. This content is safeguarded under applicable intellectual property laws and international agreements. Users are prohibited from copying, distributing, making available, disassembling, altering, decompiling, reverse engineering, translating, adapting, renting, loaning, using, leasing, granting rights to third parties, or using any methods, manual or automated, to scrape content from the Platform without explicit prior written permission from Buckets.</p>
                      <p>6.2 Trademark Use. Users are forbidden from using the Buckets brand, including any word or figurative trademarks related to Buckets or the Platform, or any third-party trademarks, without the prior consent of the trademark owner. Any use of these trademarks must not imply sponsorship, endorsement, or association with Buckets unless authorized explicitly in writing by Buckets.</p>
                      <p>6.3 User Feedback. Should a user provide any feedback or suggestions regarding the Platform or Buckets (collectively, "Feedback"), Buckets is entitled to use such Feedback without any restrictions. The user irrevocably transfers all rights, title, and interest in the Feedback to Buckets, ensuring that Buckets can utilize these insights to enhance its services without limitation.</p>
                      <br></br>

                      <h2>7. FORCE MAJEURE</h2>
                      <p>The availability of the Platform may be influenced by external factors beyond Buckets' control, including bandwidth limitations, equipment malfunctions, actions or inactions of third parties, or force majeure events. Such events encompass, but are not limited to, strikes, work stoppages, accidents, acts of war or terrorism, civil or military disturbances, nuclear or natural disasters, and interruptions, as well as shortages of supplies, breakdowns, losses, or malfunctions in utilities, communications, or computer services (both software and hardware). Buckets assumes no liability for any disruptions or unavailability of the Platform resulting from these uncontrollable circumstances.</p>
                      <br></br>

                      <h2>8. INDEMNIFICATION</h2>
                      <p>The user agrees to exonerate, defend, and exculpate Buckets, including its subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees, against all claims or demands, including legal fees, that arise from or are connected to the user's infringement of these Terms, the user's utilization of the Platform, or the user's transgression of any law or third-party rights.</p>
                      <br></br>

                      <h2>9. SEVERITY</h2>
                      <p>Should any part of these Terms be deemed illegal, invalid, or unenforceable, said provision will still be enforced to the maximum extent allowed by relevant law, and the unenforceable segment will be considered detached from these Terms. This will not impact the legitimacy and enforceability of the remaining sections.</p>
                      <br></br>

                      <h2>10. GOVERNING LAW AND DISPUTES</h2>
                      <p>10.1 Applicable Law. The interpretation and governance of these Terms shall be in line with Swiss law, setting aside any conflict of laws principles.</p>
                      <p>10.2 Resolution of Disputes. Disputes stemming from or connected to these Terms are to be settled through binding arbitration, not in a courtroom, except where mandated differently by applicable law.</p>
                      <br></br>

                      <h2>11. MISCELLANEOUS</h2>
                      <p>11.1 Right to Amend. Buckets maintains the prerogative to alter these Terms at will, with changes taking effect upon the publication of the revised version on the Platform. Adjustments may stem from the introduction of new functionalities to the Platform, shifts in legal, regulatory, or business requirements. Users bear the responsibility to periodically examine these Terms. Buckets will notify users of substantial revisions to the Terms, provided the user's email is on record. Continued usage of the Platform following any amendments implies the user's acquiescence to such changes. Additionally, Buckets retains the right to modify the services, Platform availability, required equipment, resources, third-party services, and any other Platform aspects at any given time.</p>
                      <p>11.2 Non-transferability by User. Users are prohibited from assigning their rights under these Terms. Conversely, Buckets retains the right to assign its rights and duties under these Terms, in whole or in part, to any third party.</p>
                      <p>11.3 Complete Agreement. These Terms, along with all referenced documents, constitute the full agreement between the user and Buckets, superseding any prior understandings or agreements.</p>
                      <br></br>

                      <h2>12. CONTACT</h2>
                      <p>Should you have any inquiries regarding these Terms, please direct them to Buckets via email at contact@buckets.fi.</p>
                </div>
            </div>
        </div>
      )}
    </div>
  );
};


const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleModal} className="hover:underline text-white py-2 px-4">
        Privacy Policy
      </button>      
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-auto flex bg-gray-500 bg-opacity-75">
            <div className="relative p-8 bg-white w-full max-w-6xl m-auto flex-col flex rounded-lg">
                <span className="absolute top-0 right-0 p-4">
                  <button onClick={toggleModal} className="text-dark font-semibold">Close</button>
                </span>
                <div className="text-dark overflow-y-auto max-h-[80vh]">
                    <br></br>
                    <br></br>
                    <h1><strong>BUCKETS – PRIVACY POLICY</strong> </h1>
                    <br></br>
                    <p>Effective date: 19 April 2024</p>
                    <p>Buckets prioritizes the safeguarding of your personal data. This privacy policy elaborates on Buckets' practices for the collection, utilization, and disclosure of your personal data provided via the Platform. It also details your rights and options concerning your personal data. You are encouraged to review this privacy policy thoroughly.</p>
                    <br></br>
                    <ol>
                      <li>1. GENERAL</li>
                      <li>2. PERSONAL DATA COLLECTION BY BUCKETS</li>
                      <li>3. DURATION OF PERSONAL DATA RETENTION</li>
                      <li>4. PERSONAL DATA ACCESSIBILITY</li>
                      <li>5. PERSONAL DATA PROTECTION MEASURES AT BUCKETS</li>
                      <li>6. YOUR PRIVACY RIGHTS CONCERNING PERSONAL DATA</li>
                      <li>7. CONTACT</li>
                    </ol>
                    <br></br>

                    <h2>1. GENERAL</h2>
                    <p>1.1 Scope of the Privacy Policy. This Privacy Policy explains how Buckets processes personal data obtained from individuals ("you" and "your") via its online platform <a href="https://www.buckets.fi">https://www.buckets.fi</a>, related domains, software, and services (together known as the "Platform"). It does not extend to third-party websites, applications, or software that may link to or interact with the Platform, nor to any third-party products and services, which are governed by their respective privacy policies.</p>
                    <p>1.2 Collection of Minors' Data. In line with the Buckets Terms of Services, the Platform is available to those able to form legally binding agreements. Buckets does not intentionally gather personal information from individuals under the age of 13. If it comes to your attention that a minor under your guardianship has provided personal data to Buckets, please reach out to Buckets promptly to ensure the deletion of the minor's information.</p>
                    <p>1.3 Duration of the Privacy Policy. This Privacy Policy is effective from the date specified at its beginning and will continue to be in effect until it is either terminated or revised by Buckets.</p>
                    <p>1.4 Modifications to the Privacy Policy. Buckets may revise this Privacy Policy as needed to reflect changes in business operations, the functionality of the Platform, legal regulations, or industry standards. Updated versions of the Privacy Policy will be posted on the Platform with an amended effective date. Regularly reviewing this Privacy Policy is recommended to remain updated on its terms. If Buckets possesses your contact information (e.g., email address), you will be notified about impending modifications.</p>
                    <br></br>

                    <h2>2. PERSONAL DATA COLLECTION BY BUCKETS</h2>
                    <p>2.1 Sources of Personal Data Acquisition. Buckets gathers your personal data from varied sources:</p>
                    <ul>
                      <li>Directly from your submissions, such as when you provide your personal details to Buckets during communications.</li>
                      <li>From your interactions on the Platform. Buckets automatically collects technical data about your usage of the Platform using cookies and analytics tools.</li>
                      <li>From external parties. Information about you may be obtained by Buckets from third parties if they have the legal grounds to share your personal data with us.</li>
                    </ul>
                    <p>2.2 Categories of Personal Data Collected. In adherence to the principles of data minimization, Buckets collects only the essential personal data required for your interaction with the Platform, as detailed further. Buckets will not repurpose your personal data for alternative uses without obtaining your explicit consent for such new objectives.</p>
                    <ul>
                      <li>Subscription to the mailing list: When you subscribe to the mailing list, Buckets captures your email address to include you on the list, update you on new developments or the Platform, reach out if needed, and for record-keeping. The processing is grounded on your consent and Buckets's legitimate interests to operate, analyze, expand, and manage the Platform. Your email remains on file until you opt out of the mailing list.</li>
                      <li>Account and digital wallet creation: Upon account creation, Buckets collects your email or wallet address. For wallet creation, information pertinent to your wallet, like transaction history, is requested to provide services. This data is processed by <a href="https://dynamic.xyz">https://dynamic.xyz</a>, a third-party authentication service. Your email is used for verification codes, account registration and management, and necessary contact. Wallet information is for service provision. Buckets never solicits your private keys or any other sensitive access information. Your email and wallet information are retained as long as you utilize Buckets's services. Note, Buckets doesn't store wallet passwords, private keys, or seed phrases, and can't aid in account access if these are lost, potentially resulting in the permanent loss of assets in the wallet.</li>
                      <li>Inquiries: When you contact Buckets via email, we collect your first and last name, email address, and any information included in your message. This data is used to respond to your inquiries and deliver the information you request. The processing is based on Buckets's legitimate interests to promote and expand the Platform, and your consent for any additional personal data. This data is held as long as you are in communication with Buckets.</li>
                    </ul>
                    <p>2.3 Cookies and Tracking Technologies: As you navigate the Platform, Buckets collects data through cookies and employs other tracking technologies. This information is utilized to analyze your usage patterns on the Platform, manage marketing efforts, prevent fraudulent activities, and bolster security. The legal foundations for this data collection are Buckets's legitimate interests in analyzing usage and safeguarding the Platform, as well as your consent. This data will be retained as long as necessary for analytics purposes or until you withdraw your consent.</p>
                    <p>2.4 Collection of Sensitive Data: Buckets does not collect or access any sensitive personal data, defined as information relating to your health, religious and political beliefs, racial origins, professional or trade association membership, or sexual orientation, unless voluntarily provided by you.</p>
                    <p>2.5 Consequences of Withholding Personal Data: If you choose not to provide personal data when requested, Buckets may be unable to perform the required service, potentially limiting your ability to fully utilize the Platform, obtain necessary information, or receive a response. If you believe the personal data Buckets collects is unnecessary or excessive for the intended purpose, please contact Buckets immediately.</p>
                    <p>2.6 Collection of Analytics Data: While you use the Platform, Buckets gathers specific analytics data to evaluate your interactions with the site. Buckets partners with third-party analytics providers, such as Google Analytics, to collect this data. This helps Buckets understand how users engage with the Platform, allowing for enhancements in functionality and user experience.</p>
                    <p>The data you provide when accessing the Platform can include:</p>
                    <ul>
                      <li>URL addresses from which you access the Platform;</li>
                      <li>Your device type;</li>
                      <li>Your operating system;</li>
                      <li>Your browser name and version;</li>
                      <li>Your network;</li>
                      <li>Geolocation data;</li>
                      <li>Your online behavior, such as cookie-generated data</li>
                    </ul>
                    <p>2.7 Purpose of Using Analytics Data: Buckets utilizes your analytics data to gain insights into how you discover and interact with the Platform. This data helps Buckets measure user engagement, identify areas of interest on the Platform, enhance content, develop new products and services, and address security concerns and potential abuses. While much of this analytics data is non-personal and does not personally identify you, some elements, such as your IP address, do constitute personal data. In processing such personal data, Buckets relies on the legal grounds of 'legitimate interests' for analyzing, improving, and securing the Platform, as well as on 'your consent'.</p>
                    <p>2.8 Retention of Feedback: If you reach out to Buckets with questions, complaints, recommendations, or compliments, Buckets may retain records of these communications and the responses provided. Buckets strives to de-identify this data, removing any personal information that isn't necessary for maintaining these records, thereby protecting your privacy while preserving valuable feedback.</p>
                    <p>2.9 Commercial Communications: If you opt-in for a newsletter, subscribe to it, or utilize the Platform, Buckets will keep you informed about new services, features, and special offers. The legal grounds for these communications are 'your consent' (if you explicitly opt-in) and 'pursuing legitimate business interests' (specifically, promoting the Platform). You can withdraw your consent and stop receiving commercial messages at any time without cost by clicking the "unsubscribe" link found in the newsletters or by directly contacting Buckets.</p>
                    <p>2.10 Transactional Notices: Buckets may send you essential informational messages if it possesses your email address and deems it necessary. These messages are strictly "if-needed" and are not considered commercial communications that would require your prior consent. As these are service-related notices, you do not have the option to opt-out from receiving them. These notices ensure that you are kept informed about important updates or changes affecting your service or transactions.</p>
                    <br></br>

                    <h2>3. DURATION OF PERSONAL DATA RETENTION</h2>
                    <p>3.1 Duration of Personal Data Retention: Buckets and its data processors retain your personal data only as long as necessary for the purposes outlined in this Privacy Policy or until you request that your personal data be updated or deleted, whichever occurs first. Once your personal data is no longer needed for its intended purposes and no legal basis for its retention exists, Buckets will securely delete your personal data from its systems immediately. Buckets commits to not retaining any personal data beyond what is strictly necessary.</p>
                    <p>3.2 Retention of Non-Personal Data: Buckets retains non-personal data related to you for as long as necessary to fulfill the purposes described in this Privacy Policy. This retention may include keeping non-personal data for the duration required to review Buckets's business operations, fulfill contractual obligations, pursue legitimate interests, conduct audits, comply with legal obligations, resolve disputes, and enforce agreements.</p>
                    <p>3.3 Legal Obligations for Data Retention: When there are legal requirements obliging Buckets to retain your personal data for a specified period (e.g., for maintaining business records), Buckets will store this data for the duration mandated by applicable laws. Once the legally required retention period expires, Buckets will promptly delete the personal data. This ensures compliance with legal standards while respecting your privacy rights.</p>
                    <br></br>

                    <h2>4. PERSONAL DATA ACCESSIBILITY</h2>
                    <p>Disclosure to Third-Party Service Providers: Buckets maintains your personal data with strict confidentiality. However, it may disclose your personal data to third-party service providers (data processors) when necessary for the purposes for which the personal data was collected. Such disclosures are made to service providers offering technical support, including email distribution, security, and authentication services to facilitate the operation of the Platform. Buckets does not sell your personal data to third parties and has no plans to do so in the future. Disclosure to third parties occurs under the following circumstances:</p>
                    <ul>
                      <li>To ensure the proper functioning of the Platform;</li>
                      <li>To deliver the services you have requested;</li>
                      <li>To respond to your inquiries;</li>
                      <li>To pursue legitimate business interests;</li>
                      <li>To enforce Buckets's rights, prevent fraud, and ensure security;</li>
                      <li>To fulfill Buckets's contractual obligations;</li>
                      <li>For law enforcement purposes; or</li>
                      <li>If you have explicitly consented to the disclosure.</li>
                    </ul>
                    <br></br>

                    <h2>5. PERSONAL DATA PROTECTION MEASURES AT BUCKETS</h2>
                    <p>5.1 Security Measures by Buckets: Buckets is committed to maintaining the security of your personal data through comprehensive measures. These include:</p>
                    <ul>
                      <li>Proper authentication: ensuring that access controls are in place to authenticate users reliably.</li>
                      <li>Secured networks: utilizing networks that are safeguarded against unauthorized access.</li>
                      <li>Strong passwords: encouraging the use of robust passwords by users and staff.</li>
                      <li>Limited access: restricting access to your personal data within Buckets to personnel who need it to perform their job functions.</li>
                      <li>Anonymization: where feasible, anonymizing personal data to further protect user privacy.</li>
                      <li>Limited third-party disclosure: disclosing personal data to third parties only when absolutely necessary and under strict confidentiality agreements.</li>
                      <li>Encryption: using strong encryption protocols to secure data in transit and at rest.</li>
                      <li>Anti-phishing measures: implementing strategies to prevent phishing and protect against untrusted data injections.</li>
                      <li>Carefully selected data processors: working only with data processors who meet high security standards.</li>
                    </ul>
                    <p>5.2 Response to Security Breaches: While Buckets takes significant precautions to protect your personal data, the nature of the Internet and information technology means that absolute security cannot be guaranteed. You acknowledge and accept the inherent risks associated with online data transmission. In the event of a serious security breach, Buckets will:</p>
                    <ul>
                      <li>Act swiftly: implement reasonable measures to mitigate the effects of the breach in compliance with applicable law.</li>
                      <li>Limit liability: Buckets’s liability in the event of a breach will be capped to the maximum extent allowed by law.</li>
                    </ul>
                    <p>You play a crucial role in maintaining the security of your personal data. It is vital that you keep your security credentials confidential and manage your wallet securely to prevent unauthorized access. By adhering to these guidelines, you can help strengthen the overall security posture and ensure your data remains protected.</p>
                    <br></br>

                    <h2>6. YOUR PRIVACY RIGHTS CONCERNING PERSONAL DATA</h2>
                    <p>6.1 Rights Regarding Personal Data: As a user of Buckets, you are entitled to various rights under data protection laws, provided there are no legal exceptions. These rights include:</p>
                    <ul>
                      <li>Right of Access: You can request a copy of your personal data stored by Buckets along with the purposes for which it is processed.</li>
                      <li>Right to Rectification: You are entitled to correct any inaccurate personal data that Buckets holds about you.</li>
                      <li>Right to Erasure (‘Right to be Forgotten’): You can ask Buckets to delete your personal data from its systems.</li>
                      <li>Right to Restriction: You have the option to request that Buckets limits the processing of your personal data.</li>
                      <li>Right to Data Portability: You can request your personal data in a structured, commonly used, and machine-readable format, and have it transferred to another data processor.</li>
                      <li>Right to Object: You can object to the processing of your personal data by Buckets.</li>
                      <li>Right to Withdraw Consent: If you have given consent to the processing of your data, you have the right to withdraw it at any time.</li>
                      <li>Right to Lodge a Complaint: You can file a complaint about Buckets’s handling of your personal data.</li>
                    </ul>
                    <p>6.2 Exercising Your Rights: To exercise any of the above rights, contact Buckets using the contact details provided in the Privacy Policy. Please specify your request clearly. Buckets may require you to provide an identifying detail for verification purposes. Your request will be addressed within a reasonable timeframe, but no later than 30 days.</p>
                    <p>6.3 Filing Complaints: If you have concerns about how Buckets processes your personal data, you are encouraged to contact Buckets directly to express your concerns. Buckets will investigate and respond to your complaint promptly, within 30 days. If you are dissatisfied with the response, you have the right to file a complaint with your local data protection authority, ensuring an additional level of oversight and remediation.</p>
                    <br></br>

                    <h2>7. CONTACT</h2>
                    <p>If you have any questions regarding this Privacy Policy, your rights, or Buckets' data protection practices, please reach out via email at <a href="mailto:contact@buckets.fi">contact@buckets.fi</a>.</p>                
                </div>
            </div>
        </div>
      )}
    </div>
  );
};

const RiskDisclaimer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleModal} className="hover:underline text-white py-2 px-4">
        Risks Disclaimer
      </button>      
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-auto flex bg-gray-500 bg-opacity-75">
            <div className="relative p-8 bg-white w-full max-w-6xl m-auto flex-col flex rounded-lg">
                <span className="absolute top-0 right-0 p-4">
                  <button onClick={toggleModal} className="text-dark font-semibold">Close</button>
                </span>
                <div className="text-dark overflow-y-auto max-h-[80vh]">
                    <br></br>
                    <br></br>

                    <h1><strong>BUCKETS – RISKS DISCLAIMER</strong></h1>
                    <br></br>
                    <p>Effective date: 19 April 2024</p>
                    <br></br>

                    <p>Your use of the platform <a href="https://buckets.fi">https://buckets.fi</a>, along with its associated software applications and services (collectively referred to as the "Platform"), entails certain risks that every potential investor should be aware of. Please thoroughly review this Risks Disclaimer prior to using the Platform or investing any funds into a decentralized finance (“DeFi”) protocol. If you do not understand or agree with any part of this Disclaimer or the Buckets Terms and Conditions, you should refrain from using the Platform.</p>
                    
                    <p>Please be aware that despite the extensive measures taken by both you and Buckets, certain inherent risks may still exist when using the Platform. These risks are part of engaging with technologies such as smart contracts and decentralized finance (DeFi) platforms. The list provided here is exemplary and not exhaustive:</p>
                    <br></br>

                    <ol>
                      <li><strong>Risks related to smart contracts:</strong> Your interaction with the Platform relies on smart contracts, which could be vulnerable to various types of malicious attacks. Hackers might exploit these vulnerabilities to steal cryptocurrency. It is crucial to perform thorough research on any DeFi product before investing to ensure that the associated risks are manageable. Consider the following factors during your research:
                        <ul>
                          <li>a. Developers: Investigate the developers behind the project. Who are they, and what experience do they have in DeFi? Typically, the developers and their teams are not anonymous, and their identities can offer insights into the reliability and potential of the protocol.</li>
                          <li>b. Security audits: While security audits can provide valuable information about the robustness of the smart contract, they do not eliminate the possibility of future breaches or hacks.</li>
                          <li>c. Reputation of the project: Research any reputational issues related to the project you plan to invest in. Public perception and past incidents can shed light on potential risks.</li>
                        </ul>
                      </li>
                      <br></br>
                      <li><strong>Risks related to market volatility:</strong> DeFi assets are known for their high volatility, much like traditional financial markets. It is crucial to be aware that price fluctuations in DeFi can be particularly drastic. Investors need to be prepared for sudden and significant changes in asset prices, which can impact the value of investments both negatively and positively. This level of unpredictability requires a proactive and responsive approach to investment strategy.</li>
                      <br></br>
                      <li><strong>Risks related to user error:</strong> Given the fully decentralized nature of the Platform, you bear full responsibility for managing your funds and investments. A common risk involves losing access to your cryptocurrency wallet. Unlike traditional banking systems, there is often no way to recover access to a decentralized wallet if you lose your access credentials, such as your private keys. To mitigate this risk, it's essential to manage your credentials meticulously, storing them securely both online and offline to prevent loss or theft.</li>
                      <br></br>
                      <li><strong>Currency (FX) risks:</strong> Investing in DeFi exposes you to currency risks, especially when dealing with investments across different currencies. The value of your investment can be adversely affected by fluctuations in the international currency market. For instance, if you purchase DeFi assets using euros and convert part of your investment into stablecoins pegged to the U.S. dollar, a decline in the dollar's value could decrease the euro value of your stablecoins. This highlights the importance of understanding and managing FX risks when investing in assets tied to or traded in foreign currencies.</li>
                    </ol>
                
                </div>
            </div>
        </div>
      )}
    </div>
  );
};

export { TermsOfServiceModal, PrivacyPolicy, RiskDisclaimer };
