import React, { useState, useEffect } from "react";
import "./App.css";
import Card from "./components/Card";
import Badge from "./components/Badge";
import Button from "./components/Button";
import AreaChart from "./components/AreaChart";
import RCarousel from "./components/RCaousel";

import bookmark from "./assets/icons/bookmark.svg";
import bolt from "./assets/icons/icons8-lightning-40.png";
import balance from "./assets/icons/icons8-balance-scale-48.png";
import growth from "./assets/icons/icons8-growth-48.png";
import lock from "./assets/icons/icons8-locked-with-key-48.png";
import money from "./assets/icons/icons8-money-bag-48.png";
import buckets from "./assets/icons/buckets.png";
import usdc from "./assets/icons/tokens/usdc.svg";
import dai from "./assets/icons/tokens/dai.svg";
import btc from "./assets/icons/tokens/btc.svg";
import aave from "./assets/icons/tokens/aave.svg";
import eth from "./assets/icons/tokens/eth.svg";

import { TermsOfServiceModal, PrivacyPolicy, RiskDisclaimer } from "./legalDocs";

import { CardContainer, CardBody, CardItem } from "./components/ui/ThreeDCard";
import { Boxes } from "./components/ui/BoxesBackground";

function App() {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/assets/screenshot_buckets_page.png`);
  const [opacity, setOpacity] = useState({ img1: 1, img2: 0 });
  const [navbarTextColor, setNavbarTextColor] = useState("text-white");
  const [logoSrc, setLogoSrc] = useState("/Logo.png");
  var ReactRotatingText = require("react-rotating-text");
  const [showTerms, setShowTerms] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;

    // Logic for image opacity transition
    const startTransition = 200;
    const endTransition = 300;
    let opacityImg1, opacityImg2;
    if (position <= startTransition) {
      opacityImg1 = 1;
      opacityImg2 = 0;
    } else if (position >= endTransition) {
      opacityImg1 = 0;
      opacityImg2 = 1;
    } else {
      const progress = (position - startTransition) / (endTransition - startTransition);
      opacityImg1 = 1 - progress;
      opacityImg2 = progress;
    }
    setOpacity({ img1: opacityImg1, img2: opacityImg2 });

    // Logic for top bar blur effect
    setIsScrolled(position > 0);

    // Logic for changing navbar text color
    const whiteBgElementPosition = document.getElementById("features").offsetTop; // Adjust ID accordingly
    const whiteBgElementHeight = document.getElementById("features").offsetHeight; // Adjust ID accordingly

    console.log(position >= whiteBgElementPosition);
    console.log(position < whiteBgElementPosition + whiteBgElementHeight);
    if (position >= whiteBgElementPosition && position < whiteBgElementPosition + whiteBgElementHeight) {
      setNavbarTextColor("text-dark"); // Change to black when over white background
      setLogoSrc("/Logo_dark.png"); // Change to dark version of the logo
    } else {
      setNavbarTextColor("text-white"); // Change back to white otherwise
      setLogoSrc("/Logo.png"); // Change back to default logo
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (e) => {
    setEmail(e.target.value);
    const isValid = validateEmail(e.target.value);
    setIsEmailValid(isValid);
  };

  const validateEmail = (email) => {
    // Simple email format validation using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    if (!isEmailValid || !email) {
      e.preventDefault();
      alert("Please enter a valid email address.");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://getlaunchlist.com/js/widget-diy.js";
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen dark">
      {/* Navbar */}
      <div className={`fixed top-0 left-0 w-full z-10 px-4 ${isScrolled ? "bg-transparent backdrop-blur-md" : ""}`}>
        <div className="container mx-auto">
          <div className={`flex items-center justify-between py-4 ${navbarTextColor}`}>
            <img src={logoSrc} alt="Buckets.fi - the number 1 DeFi scanner" className="w-24 sm:w-32 md:w-40 lg:w-48" />
            <div className="flex space-x-2 sm:space-x-4">
              <a className="text-sm sm:text-base no-underline hover:text-pink-500 p-2" href="https://docs.buckets.fi" target="_blank" rel="noopener noreferrer">
                Docs
              </a>
              <button className="bg-blue-500 text-sm sm:text-base text-white py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out" onClick={() => window.open("https://app.buckets.fi", "_blank")}>
                Open app
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="pt-24 lg:pt-32 flex flex-col lg:flex-row items-center mb-16 px-4 lg:px-24">
        {/* Left side: Text content */}
        <div className="flex flex-col w-full lg:w-1/2 justify-center mb-8 lg:mb-0 lg:pr-8 text-center lg:text-left">
          <h1 className="mb-2 text-4xl md:text-5xl lg:text-6xl text-white font-bold leading-tight">
            <span className="rotating-text text-blue-500">
              <ReactRotatingText items={["Digital assets", "Web3", "Yields"]} />
            </span>
            <br className="hidden sm:block" />made simple
          </h1>
          <p className="leading-normal text-lg md:text-xl lg:text-2xl mb-6 text-white">
            Number 1 DeFi scanner for risk-adjusted investment opportunities.
          </p>
          <div className="mt-6">
            <button
              className="px-6 py-3 rounded-xl text-lg font-semibold bg-blue-500 text-white hover:bg-blue-600 transition-colors"
              onClick={() => window.open("https://app.buckets.fi", "_blank")}
            >
              Try now →
            </button>
          </div>
        </div>

        {/* Right side: CardContainer */}
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
          <CardContainer className="inter-var w-full max-w-xl mx-auto">
            <CardBody className="bg-gray relative group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-gray-800 dark:border-white/[0.2] border-black/[0.1] w-full h-auto rounded-xl p-6 md:p-8 border">
              <CardItem
                as="p"
                translateZ="60"
                className="text-white text-base md:text-lg mb-4 md:mb-6"
              >
                Grow your wealth with <span className="text-blue-500">smart investing</span> tools <img src={bolt} alt="lightning bolt" className="h-4 md:h-6 lg:h-8 inline-block" /> 
              </CardItem>
              <CardItem translateZ="100" className="w-full relative aspect-video">
                <div className="absolute inset-0 overflow-hidden rounded-xl">
                  <img 
                    src={`${process.env.PUBLIC_URL}/assets/screenshot_buckets_page.png`} 
                    alt="Original Buckets page" 
                    className="w-full h-full object-contain transition-opacity duration-500 absolute top-0 left-0"
                    style={{ opacity: opacity.img1 }}
                  />
                  <img 
                    src={`${process.env.PUBLIC_URL}/assets/screenshot_buckets_page_white.png`} 
                    alt="White Buckets page" 
                    className="w-full h-full object-contain transition-opacity duration-500 absolute top-0 left-0"
                    style={{ opacity: opacity.img2 }}
                  />
                </div>
              </CardItem>
            </CardBody>
          </CardContainer>
        </div>
      </div>

      {/* Stats Section */}
      <div className="container mx-auto px-4 md:px-8 mb-16">
        <div className="text-white w-full overflow-hidden text-center">
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="text-center flex-1 mb-6 sm:mb-0">
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold">200+</h2>
              <p className="text-sm md:text-base">user-created buckets</p>
            </div>
            <div className="text-center flex-1 mb-6 sm:mb-0">
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold">20k+</h2>
              <p className="text-sm md:text-base">assets tracked</p>
            </div>
            <div className="text-center flex-1">
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold">100k+</h2>
              <p className="text-sm md:text-base">daily risk metrics</p>
            </div>
          </div>
        </div>
      </div>

      {/* Email Form */}
      <div className="flex items-center justify-center px-4 mb-16">
        <form className="bg-gray w-full md:w-2/3 lg:w-1/2 shadow-lg rounded-lg px-6 py-8" action="https://getlaunchlist.com/s/0ldWYW" onSubmit={handleSubmit} method="POST" target="_blank">
          <div className="mb-4">
            <label className="block text-blue-300 text-lg font-bold mb-2" htmlFor="emailaddress">
              Join the community
            </label>
            <input className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:ring transform transition hover:scale-105 duration-300 ease-in-out" id="emailaddress" type="text" name="email" placeholder="you@somewhere.com" onChange={handleChange} value={email} />
            <p className="mt-2 text-xs text-white italic">We respect your privacy and will only notify you about app updates.</p>
          </div>
          <div className="flex items-center justify-between pt-4">
            <button className="bg-gradient-to-r from-purple-800 to-green-500 hover:from-pink-500 hover:to-green-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out" type="submit">
              I'm in!
            </button>
          </div>
        </form>
      </div>

      {/* Scrolling Text */}
      <div className="scrolling-text-container mt-8 mb-16">
        <div className="scrolling-text italic font-bold text-sm md:text-base">
          <span>Permissionless</span>
          <span>Decentralized Finance</span>
          <span>Non-custodial</span>
          <span>Smart Contracts</span>
          <span>Open-Source</span>
          <span>Interoperability</span>
          <span>Programmable Money</span>
          <span>Wallets</span>
          <span>Financial inclusion</span>
          <span>Digital Assets</span>
          <span>Cryptocurrencies</span>
          <span>Transparency</span>
          <span>Permissionless</span>
          <span>Decentralized Finance</span>
          <span>Non-custodial</span>
          <span>Smart Contracts</span>
          <span>Open-Source</span>
          <span>Interoperability</span>
          <span>Programmable Money</span>
          <span>Wallets</span>
          <span>Financial inclusion</span>
          <span>Digital Assets</span>
          <span>Cryptocurrencies</span>
          <span>Transparency</span>
          <span>Permissionless</span>
          <span>Decentralized Finance</span>
          <span>Non-custodial</span>
          <span>Smart Contracts</span>
          <span>Open-Source</span>
          <span>Interoperability</span>
          <span>Programmable Money</span>
          <span>Wallets</span>
          <span>Financial inclusion</span>
          <span>Digital Assets</span>
          <span>Cryptocurrencies</span>
          <span>Transparency</span>
          <span>Permissionless</span>
          <span>Decentralized Finance</span>
          <span>Non-custodial</span>
          <span>Smart Contracts</span>
          <span>Open-Source</span>
          <span>Interoperability</span>
          <span>Programmable Money</span>
          <span>Wallets</span>
          <span>Financial inclusion</span>
          <span>Digital Assets</span>
          <span>Cryptocurrencies</span>
          <span>Transparency</span>
        </div>
      </div>

      {/* Benefits Section */}
      <section id="benefits" className="benefits relative overflow-hidden py-16 md:py-24 mb-16">
        <div className="absolute inset-0 z-0">
          <Boxes className="w-full h-full pointer-events-auto" />
        </div>
        <div className="relative z-10 bg-gray-900/30 pointer-events-none">
          <div className="container mx-auto px-4 md:px-20 pointer-events-auto">
            <div className="text-white w-full overflow-hidden text-center mb-12">
              <h1 className="text-2xl md:text-3xl lg:text-4xl text-white text-center font-bold leading-tight mb-6">
                When finance meets technology...
              </h1>
              <img
                src={process.env.PUBLIC_URL + "/assets/icons8-double-down.gif"}
                alt="Animated arrow"
                className="mx-auto mt-4 w-8 md:w-12"
                style={{ filter: "invert(100%)" }}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="text-white text-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons8-security-lock-96.png"}
                  alt="Lock"
                  className="w-12 h-12 mx-auto mb-4"
                />
                <h2 className="text-xl lg:text-2xl font-bold mb-2">
                  Highly secure
                </h2>
                <p className="text-sm lg:text-base">
                  Powerful cryptography makes it impossible for anyone to access your
                  funds without your secret seed phrase.
                </p>
              </div>

              <div className="text-white text-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons8-eye-96.png"}
                  alt="Eye as a symbol for transparency"
                  className="w-12 h-12 mx-auto mb-4"
                />
                <h2 className="text-xl lg:text-2xl font-bold mb-2">
                  High-speed & transparent
                </h2>
                <p className="text-sm lg:text-base">
                  You always know exactly where your money is, thanks to real-time
                  updates and a public ledger of transactions.
                </p>
              </div>

              <div className="text-white text-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons8-access-96.png"}
                  alt="A key representing ownership"
                  className="w-12 h-12 mx-auto mb-4"
                />
                <h2 className="text-xl lg:text-2xl font-bold mb-2">
                  Interoperable
                </h2>
                <p className="text-sm lg:text-base">
                  One account, every apps. Your wallet is always portable, allowing you 
                  to disconnect from any app at any time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features py-16 md:py-24 mb-16">
        <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">What is Buckets all about?</h2>
          {/* Feature 1 */}
          <div className="flex flex-col md:flex-row-reverse items-center mb-16 md:mb-24">
            <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pr-8">
              <h2 className="text-xl md:text-2xl font-bold mb-4 text-center md:text-left">
                <span className="text-blue-500">Risk-adjusted</span> returns
              </h2>
              <p className="text-base md:text-lg mb-4 text-center md:text-left">
                Unlock investment opportunities tailored to your risk profile,
                maximizing potential returns while managing exposure.
                Create compositions of assets and simulate risks scenarios for them.
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <img src={process.env.PUBLIC_URL + "/assets/risk_score_metrics.jpeg"} alt="DeFi and Cryptocurrencies Analytics tools for Portfolio Management" className="w-4/5 mx-auto rounded-2xl" />
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex flex-col md:flex-row items-center mb-16 md:mb-24">
            <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pr-8">
              <h2 className="text-xl md:text-2xl font-bold mb-4 text-center md:text-left">
                <span className="text-blue-500">DeFi</span> at your fingertips
              </h2>
              <p className="text-base md:text-lg mb-4 text-center md:text-left">
                Access Decentralized Finance apps with a single interface.
                Scan through thousands of cryptoassets and liquidity pools and instantly get relevant metrics and information.
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <div className="w-full md:w-3/5 mx-auto">
                <RCarousel data={Array.from({ length: 7 })}>
                  {Array.from({ length: 7 }, (_, i) => (
                    <div className="mx-2 bg-gray rounded-xl" key={i + 1}>
                      <img src={`${process.env.PUBLIC_URL}/assets/buckets/${i + 1}.jpg`} className="rounded-2xl w-full" alt={`Bucket ${i + 1}`} />
                    </div>
                  ))}
                </RCarousel>
              </div>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex flex-col md:flex-row-reverse items-center mb-16 md:mb-24">
            <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pl-8">
              <h2 className="text-xl md:text-2xl font-bold mb-4 text-center md:text-left">
                Assess <span className="text-blue-500">risks</span> exposures seamlessly
              </h2>
              <p className="text-base md:text-lg mb-4 text-center md:text-left">
                Understanding your risk is an important part of investing. Discover advanced analytics and scores, simulate your scenarios, and fit your investments to your risk tolerance.
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <img src={process.env.PUBLIC_URL + "/assets/Risk Score Dark.png"} alt="DeFi and Cryptocurrencies Analytics tools for Portfolio Management" className="w-2/5 mx-auto rounded-2xl" />
            </div>
          </div>

          {/* Feature 4 */}
          <div className="text-center mb-16">
            <h2 className="text-xl md:text-2xl font-bold mb-4">
              Trade on <span className="text-blue-500">multi-chains</span> easily
            </h2>
            <img src={process.env.PUBLIC_URL + "/assets/logo_multichains2.png"} alt="Multi-chains with logos of Ethereum, Avalance, Polygon, and OP ecosystems" className="w-full max-w-3xl mx-auto rounded-2xl" />
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <div className="container mx-auto px-4 mb-16">
        <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-8 md:space-x-16 lg:space-x-24">
          <a target="_blank" rel="noopener noreferrer" href="https://www.aave.com" className="mb-6 sm:mb-0">
            <img src="/assets/partners/aave/aave-logo.svg" className="w-32 sm:w-36 md:w-40" alt="Aave Logo" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.startup.google.com" className="mb-6 sm:mb-0">
            <img src="/assets/partners/google/Google_for_Startups_logo.svg" className="w-40 sm:w-44 md:w-48" alt="Google for Startups Logo" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gitcoin.co">
            <img src="/assets/partners/gitcoin/gitcoin-gtc-logo.svg" className="w-16 sm:w-20 md:w-24" alt="Gitcoin Logo" />
          </a>
        </div>
      </div>

      {/* CTA Button */}
      <div className="flex items-center justify-center mb-16">
        <button 
          className="bg-blue-500 text-white font-bold py-3 px-6 rounded-xl text-lg md:text-xl focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
          onClick={() => window.open("https://app.buckets.fi", "_blank")}
        >
          Open app 🪣
        </button>
      </div>

      {/* Features Grid */}
      <div className="container mx-auto px-4 mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-100 shadow-md rounded-lg p-6 md:p-8">
            <h3 className="text-xl md:text-2xl font-bold mb-4 text-center">Non custodial</h3>
            <p className="text-base md:text-lg text-center">Nobody can access your assets or invest your funds without your consent.</p>
          </div>
          <div className="bg-gray-100 shadow-md rounded-lg p-6 md:p-8">
            <h3 className="text-xl md:text-2xl font-bold mb-4 text-center">A wallet with an email address</h3>
            <p className="text-base md:text-lg text-center">Don't want to save your seed phrase? Create a new account with your email. Easy as A-B-C.</p>
          </div>
          <div className="bg-gray-100 shadow-md rounded-lg p-6 md:p-8 col-span-1 md:col-span-2">
            <h3 className="text-xl md:text-2xl font-bold mb-4 text-center">Every asset, every chain</h3>
            <div className="scrolling-text-container2 overflow-hidden">
            <div className="scrolling-text2 flex">
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/aave.svg`} alt="Aave token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ageur.svg`} alt="ageur token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/btc.svg`} alt="bitcoin token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/dai.svg`} alt="DAI token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ens.svg`} alt="ens token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ftm.svg`} alt="ftm token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ldo.svg`} alt="ldo token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/matic.svg`} alt="matic token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/mkr.svg`} alt="mkr token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/reth.svg`} alt="reth token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/snx.svg`} alt="snx token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/uni.svg`} alt="uni token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/aave.svg`} alt="Aave token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ageur.svg`} alt="ageur token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/btc.svg`} alt="bitcoin token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/dai.svg`} alt="DAI token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ens.svg`} alt="ens token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ftm.svg`} alt="ftm token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ldo.svg`} alt="ldo token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/matic.svg`} alt="matic token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/mkr.svg`} alt="mkr token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/reth.svg`} alt="reth token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/snx.svg`} alt="snx token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/uni.svg`} alt="uni token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/aave.svg`} alt="Aave token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ageur.svg`} alt="ageur token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/btc.svg`} alt="bitcoin token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
                <span className="mx-2">
                  <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/dai.svg`} alt="DAI token cryptocurrency" className="w-12 h-12 md:w-16 md:h-16" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="w-full pt-16 pb-6 px-4 md:px-20">
        <div className="flex flex-col md:flex-row justify-between items-start mb-8">
          <div className="text-white text-left mb-4 md:mb-0">
            <p className="text-sm md:text-base">
              Copyright © 2024 All rights reserved
              <br />A Dapp made with ❤️ by
              <a href="https://twitter.com/buckets_fi" target="_blank" rel="noopener noreferrer" className="ml-1 text-blue-300 hover:text-pink-500">
                @buckets_fi
              </a>
            </p>
          </div>
          <div className="flex flex-col md:flex-row text-white italic text-sm md:text-base">
            <TermsOfServiceModal />
            <PrivacyPolicy />
            <RiskDisclaimer />
          </div>
        </div>
        <div className="flex items-center space-x-4 mb-4">
          <a className="text-blue-300 hover:text-pink-500" href="https://www.x.com/buckets_fi" target="_blank" rel="noopener noreferrer">
            <svg className="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
            </svg>
          </a>
          <a className="text-blue-300 hover:text-pink-500" href="https://discord.com/invite/6xUwnug39W" target="_blank" rel="noopener noreferrer">
            <svg className="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M20.317 4.37a19.791 19.791 0 00-4.885-1.515.074.074 0 00-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 00-5.487 0 12.64 12.64 0 00-.617-1.25.077.077 0 00-.079-.037A19.736 19.736 0 003.677 4.37a.07.07 0 00-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 00.031.057 19.9 19.9 0 005.993 3.03.078.078 0 00.084-.028c.462-.63.874-1.295 1.226-1.994a.076.076 0 00-.041-.106 13.107 13.107 0 01-1.872-.892.077.077 0 01-.008-.128 10.2 10.2 0 00.372-.292.074.074 0 01.077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 01.078.01c.12.098.246.198.373.292a.077.077 0 01-.006.127 12.299 12.299 0 01-1.873.892.077.077 0 00-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 00.084.028 19.839 19.839 0 006.002-3.03.077.077 0 00.032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 00-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"/>
            </svg>
          </a>
          <a className="text-blue-300 hover:text-pink-500" href="https://www.linkedin.com/company/buckets-fi/" target="_blank" rel="noopener noreferrer">
            <svg className="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
            </svg>
          </a>
        </div>

        
        <div className="text-white italic mt-4 md:px-0">
          <p>There are risks associated with investing in securities. The content presented on this website is for informational purposes only, you should not construe any such information or other material as legal, tax, investment, financial, or other advice. Nothing contained on this website constitutes a solicitation, recommendation, endorsement, or offer to buy or sell any securities or other financial instruments. Please contact us for any questions or inquiries</p>

        </div>
      </footer>
    </div>
  );
}

export default App;